<template>
  <div class="add-tag" :class="{noScollForLogin_safari:os.safari}">
       <div class="add-tag-form">
            <div class="form">
                <input type="text"  autocomplete="off" name="tag" v-model="inputValue" ref="addTagInput">
                <div class="btn" :class="{on:inputValue}" @click="onAddTag">确定</div>
            </div>
       </div>
  </div>
</template>

<script>
import { mapActions ,mapState} from "vuex";
export default {
    data() {
        return {
            type: "add",
            inputValue: "",
        };
    },
    computed:{
        ...mapState({
            os: (state) => state.os,
        })
    },
    methods: {
        ...mapActions({
            requestLabelAdd: "collect/requestLabelAdd",
            requestLabelEdit: "collect/requestLabelEdit",
        }),
        onAddTag() {
            if (this.inputValue) {
                if (this.type == "add") {
                    this.requestLabelAdd({ label_name: this.inputValue }).then(
                        () => {
                            this.$router.go(-1);
                        }
                    );
                } else {
                    this.requestLabelEdit({
                        label_name: this.inputValue,
                        label_id: this.$route.params.id,
                    }).then(() => {
                        this.$router.go(-1);
                    });
                }
            }
        },
    },
    created() {
        if (this.$route.params.id) {
            this.type = "edit";
            this.inputValue = this.$route.params.tag;
        }
    },
    mounted(){
        this.$refs.addTagInput.focus();
        this.$nextTick(() => {
            this.$refs.addTagInput.focus();
        });
        
    }
};
</script>

<style>
.add-tag{padding-top: 47px; height: 100vh; background: #fff;}
.add-tag-form{ padding:20px;}
.add-tag-form .form{width:100%; height:46px ; border-radius: 4px; background:#EDEDED; display: flex; justify-content: space-between; align-items: center;}
.add-tag-form .form input{flex:1; margin: 0; padding: 0; border: none; background: transparent; font-size: 16px; color: #2E2E30; text-indent: 20px; display: block;}
.add-tag-form .form .btn{padding:0 20px; color: #B3B3B3; font-size: 16px;}
.add-tag-form .form .btn.on{color: #F85659;}
</style>